import React from "react";
import './ArticleCard.css';

import mainApi from "../../utils/MainApi";

import editIcon from '../../assets/images/icons/editing.png';
import trashIcon from '../../assets/images/icons/trash.png';
import { useHistory } from "react-router-dom";

function ArticleCard(article) {
  const history = useHistory();

  function handleArticleEdit() {
    history.push(`/articles/${article._id}/edit`)
  }

  function handleArticleDelete() {
    article.handlePopupWithFormOpen();
    article.handleSelectArticle(article);
  }

  return (
        <article className="article-card">
          <div className="article-card__info-container">
            <img className="article-card__image" src={article.preview} alt="Превью" />
            <h3>{article.title}</h3>
          </div>
          <div className="article-card__button-container">
            <button onClick={handleArticleEdit} className="article-card__button article-card__button_edit">
              <img src={editIcon} alt="Редактировать статью" />
            </button>
            <button onClick={handleArticleDelete}  type="button" className="article-card__button article-card__button_delete">
              <img src={trashIcon} alt="Удалить статью" />
            </button>
          </div>
        </article>
)
}

export default ArticleCard;