import React from "react";

class Button extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <button type={this.props.type} onClick={this.props.onClick} className={`section__button ${this.props.className}`} disabled={this.props.disabled}>{this.props.children}</button>
    )
  }
}

export default Button;