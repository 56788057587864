class MainApi {
  constructor(options) {
    this._baseUrl = options.baseUrl;
  }

  _checkResponse(res) {
    if (!res.ok) {
        return Promise.reject(`Ошибка: ${res.status}`);
    }
    return res.json();
  }

  register(name, password, email) {
    return fetch(`${this._baseUrl}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "name": name,
        "password": password,
        "email": email })
      })
    .then(res => {
      return this._checkResponse(res);
    })};

  authorize(password, email) {
    return fetch(`${this._baseUrl}/signin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "password": password,
        "email": email
      })
    })
    .then(res => {
      return this._checkResponse(res);
    })};

  getContent(token) {
    return fetch(`${this._baseUrl}/users/me`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
      },
      withCredentials: true,
    })
    .then(res => {
      return this._checkResponse(res);
    })
  };

  uploadPreview(preview) {
      return fetch(`${this._baseUrl}/upload`, {
        // content-type header should not be specified!
        method: 'POST',
        body: preview,
      }).then(res => {
        return this._checkResponse(res);
      })
  };

  getArticles() {
    return fetch(`${this._baseUrl}/articles`, {
      // content-type header should not be specified!
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => {
      return this._checkResponse(res);
    })
  };

  getServices() {
    return fetch(`${this._baseUrl}/services`, {
      // content-type header should not be specified!
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => {
      return this._checkResponse(res);
    })
  };

  createArticle(articleData) {
    return fetch(`${this._baseUrl}/articles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        "category": articleData.category,
        "title": articleData.title,
        "description": articleData.description,
        "preview": articleData.preview,
        "url": articleData.url,
        "createdAt": articleData.createdAt,
        "tags": articleData.tags,
        "htmlCode": articleData.htmlCode,
        "metaTitle": articleData.metaTitle,
        "metaDescription": articleData.metaDescription,
      })
      })
    .then(res => {
      return this._checkResponse(res);
    })};

    updateArticle(articleData) {
      return fetch(`${this._baseUrl}/articles/${articleData.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          "category": articleData.category,
          "title": articleData.title,
          "description": articleData.description,
          "preview": articleData.preview,
          "url": articleData.url,
          "createdAt": articleData.createdAt,
          "tags": articleData.tags,
          "htmlCode": articleData.htmlCode,
          "metaTitle": articleData.metaTitle,
          "metaDescription": articleData.metaDescription,
        })
        })
      .then(res => {
        return this._checkResponse(res);
      })};

  deleteArticle(article) {
    return fetch(`${this._baseUrl}/articles/${article._id}`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
      }
    })
    .then(res => {
      return this._checkResponse(res);
    })};

    createService(serviceData) {
      return fetch(`${this._baseUrl}/services`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          "order": serviceData.order,
          "title": serviceData.title,
          "description": serviceData.description,
          "preview": serviceData.preview,
          "url": serviceData.url,
          "client": serviceData.client,
          "format": serviceData.format,
          "category": serviceData.category,
          "price": serviceData.price,
          "htmlCode": serviceData.htmlCode,
          "metaTitle": serviceData.metaTitle,
          "metaDescription": serviceData.metaDescription,
        })
        })
      .then(res => {
        return this._checkResponse(res);
      })};

      updateService(serviceData) {
        return fetch(`${this._baseUrl}/services/${serviceData._id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          },
          body: JSON.stringify({
            "order": serviceData.order,
            "title": serviceData.title,
            "description": serviceData.description,
            "preview": serviceData.preview,
            "url": serviceData.url,
            "client": serviceData.client,
            "format": serviceData.format,
            "category": serviceData.category,
            "price": serviceData.price,
            "htmlCode": serviceData.htmlCode,
            "metaTitle": serviceData.metaTitle,
            "metaDescription": serviceData.metaDescription,
          })
          })
        .then(res => {
          return this._checkResponse(res);
        })};

      deleteService(service) {
        return fetch(`${this._baseUrl}/services/${service._id}`, {
          method: 'DELETE',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          }
        })
        .then(res => {
          return this._checkResponse(res);
        })};
}


const mainApi = new MainApi({
  baseUrl: 'https://api.astrosinkevich.ru',
});

export default mainApi;