import React from 'react';
import { Route, Switch, Link, withRouter, useHistory } from "react-router-dom";
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import mainApi from '../../utils/MainApi';

import Login from '../Login/Login';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import Home from '../Home/Home';
import AddArticle from '../AddArticle/AddArticle';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Articles from '../Articles/Articles';
import ArticleEditor from '../ArticleEditor/ArticleEditor';
import Services from '../Services/Services';
import AddService from '../AddService/AddService';
import ServiceEditor from '../ServiceEditor/ServiceEditor';
import Profile from '../Profile/Profile';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editorConfiguration: {
        removePlugins: ["MediaEmbedToolbar", "Title", "Markdown"],
        simpleUpload: {
          uploadUrl: `${process.env.REACT_APP_API_URL}/upload`,
      }},
      editorData: '',
      editorPortfolioData: '',
      overviewData: '',
      executionData: '',
      resultData: '',
      preview: '',
      previewPortfolio: '',
      miniPreview: '',
      infoData: {
        path: "",
        img: "",
        text: ""
      },
      isDone: false,
      currentUser: {},
      isLoggedIn: false,
      location: this.props.history.location.pathname,
      articlesData: [{}],
      servicesData: [{}],
      errorVisible: false,
      textError: '',
    }
    // this.history = useHistory();
    this.tokenCheck = this.tokenCheck.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getServices = this.getServices.bind(this);
    this.openInfoPopup = this.openInfoPopup.bind(this);
    this.handleCloseInfoPopup = this.handleCloseInfoPopup.bind(this);
    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.setLoggedIn = this.setLoggedIn.bind(this);
    this.setLoggedOut = this.setLoggedOut.bind(this);
  }
  /** */

  componentDidMount() {
    this.getArticles();
    this.getServices();
    this.tokenCheck();
  }

  openInfoPopup(data) {
    this.setState({
      infoData: {
        path: data.path,
        img: data.img,
        text: data.text,
      },
      isDone: true,
    });
  }

  handleCloseInfoPopup() {
    this.setState({
      setInfoData: {
        path: "",
        img: "",
        text: ""
      },
      isDone: false,
    })
  }

  handleSubmitRegister(name, password, email) {
    console.log(name, password, email)
    return  mainApi.register(name, password, email)
    .then((data) => {
      return data
    })
    .catch(err => console.log(err));
  }

  handleSubmitLogin(email, password) {
    return  mainApi.authorize(password, email)
    .then((data) => {
      this.setState({
        errorVisible: false,
        textError: '',
      })
      return data
    })
    .catch((err) => {
      if(err === 'Ошибка: 401') {
        this.setState({
          errorVisible: true,
          textError: 'Неправильные почта или пароль!',
        })
      } else {
        this.setState({
          errorVisible: true,
          textError: 'Произошла ошибка на сервере. Повторите позднее.',
        })
      }
      console.log(err)
    });
  }

  tokenCheck() {
    const jwt = localStorage.getItem('jwt');
    if (jwt){
      mainApi.getContent(jwt)
      .then((res) => {
        if (res){
          this.setLoggedIn();
          this.setState({
            currentUser: res,
          })
          console.log()
          this.props.history.push(this.state.location);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  getArticles() {
    mainApi.getArticles().then((res) => {
      if(res) {
        this.setState({
          articlesData: res.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  getServices() {
    mainApi.getServices().then((res) => {
      if(res) {
        this.setState({
          servicesData: res.services.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  setLoggedIn() {
    this.setState({
      isLoggedIn: true,
    })
  }

  setLoggedOut() {
    this.setState({
      isLoggedIn: false,
    })
  }

   render() {
        return (
          <CurrentUserContext.Provider value={this.state.currentUser}>
            <EdtiorConfigContext.Provider value={this.state.editorConfiguration}>
            
            <Switch>
              <ProtectedRoute exact path='/' component={Home}
              isLoggedIn={this.state.isLoggedIn}
              articlesData={this.state.articlesData}
              servicesData={this.state.servicesData}
              />

              <ProtectedRoute path='/profile' component={Profile}
              isLoggedIn={this.state.isLoggedIn}
              setLoggedOut={this.setLoggedOut}
              />

              <ProtectedRoute path='/articles/:articleId/edit' component={ArticleEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              articlesData={this.state.articlesData}
              getArticles={this.getArticles}
              />
              <ProtectedRoute path='/articles' component={Articles} 
              isLoggedIn={this.state.isLoggedIn}
              articlesData={this.state.articlesData}
              getArticles={this.getArticles}
              />
              <ProtectedRoute path='/add-article' component={AddArticle} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              getArticles={this.getArticles}
              />
              <ProtectedRoute path='/services/:serviceId/edit' component={ServiceEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              servicesData={this.state.servicesData}
              getServices={this.getServices}
              />
              <ProtectedRoute path='/services' component={Services} 
              isLoggedIn={this.state.isLoggedIn}
              servicesData={this.state.servicesData}
              getServices={this.getServices}
              />
              <ProtectedRoute path='/add-service' component={AddService} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              getServices={this.getServices}
              />
              <Route path="/login">
                <Login handleLogin={this.setLoggedIn} setLoggedIn={this.setLoggedIn} tokenCheck={this.tokenCheck} textError={this.state.textError} errorVisible={this.state.errorVisible} handleSubmitLogin={this.handleSubmitLogin}/>
              </Route>
            </Switch>
            </EdtiorConfigContext.Provider>
          </CurrentUserContext.Provider>
        );
      }
    }
export default withRouter(App);