import React from "react";
import './PopupWIthForm.css';

function PopupWIthForm(props) {

  return(
    <div className={`popup ${props.popupName} ${props.isOpen && 'popup_opened'}` }>
      <div className="popup__container popup__container_handle-form">
        <p className="popup__title">{props.title}</p>
        <form name={props.popupName} className="popup__form" onSubmit={props.onSubmit} noValidate>
          {props.children}
        </form>
      </div>
      <button className="popup__close-button" type="button" onClick={props.onClose}></button>
  </div>
  )

}

export default PopupWIthForm;