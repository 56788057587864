import React from "react";
import './Footer.css';

import logo from '../../assets/images/logo.webp';

function Footer(props) {

  return(
    <footer className="footer">
      <div className="footer__container">
        <img className="footer__logo" src={logo} alt='Логотип'/>
      </div>
    </footer>
  )

}

export default Footer;