import React from 'react';
import './InfoTooltip.css';
import { useHistory } from 'react-router-dom';

function InfoTooltip(props) {

  const history = useHistory();

  function handleClose() {
    props.handleCloseInfoPopup();
    history.push(`${props.path}`);
  }

  return (
    <div className={`info ${props.isDone && 'info_opened'}` }>
      <div className="info__container">
        <img className="info__image" alt="Информационная картинка" src={props.img} />
        <p className="info__text">{props.text}</p>
        <button className="info__close-button" onClick={handleClose}></button>
      </div>
    </div>
  );
}

export default InfoTooltip;