import React from "react";
import './PortfolioCard.css';


import editIcon from '../../assets/images/icons/editing.png';
import trashIcon from '../../assets/images/icons/trash.png';
import { useHistory } from "react-router-dom";

function PortfolioCard(portfolio) {
  const history = useHistory();

  function handlePortfolioEdit() {
    history.push(`/services/${portfolio._id}/edit`)
  }

  function handlePortfolioDelete() {
    portfolio.handlePopupWithFormOpen();
    portfolio.handleSelectPortfolio(portfolio);
  }
  
  return (
        <article className="portfolio-card">
          <div className="portfolio-card__info-container">
            <img className="portfolio-card__image" src={portfolio.preview} alt="Превью" />
            <h3>{portfolio.title}</h3>
          </div>
          <div className="portfolio-card__button-container">
            <button onClick={handlePortfolioEdit} className="portfolio-card__button portfolio-card__button_edit">
              <img src={editIcon} alt="Редактировать портфолио" />
            </button>
            <button onClick={handlePortfolioDelete}  type="button" className="portfolio-card__button portfolio-card__button_delete">
              <img src={trashIcon} alt="Удалить портфолио" />
            </button>
          </div>
        </article>
)
}

export default PortfolioCard;