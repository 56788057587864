import React from "react";
import './InfoMessage.css';

class InfoMessage extends React.Component {

  render() {
    return(
        <div className={`info-message ${this.props.infoMessageConfig.isShowInfoMessage && 'info-message_show'} ${this.props.infoMessageConfig.responseState}`}>
          <button className="info-message__close-button" onClick={this.props.closeInfoMessage}></button>
          <p className="info-message__text">{this.props.infoMessageConfig.text}</p>
        </div>
      )
  }
}

export default InfoMessage;